import * as React from "react"

export default function ModelListing({ name, short, onClick }) {

    return (<a
        class="relative block h-full rounded-xl border border-gray-100 p-3 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring flex flex-col justify-end"
        onClick={() => { if (onClick != null) onClick(); }}
        href={`/models/${name.toLowerCase()}`}
    >
        <h2 class="font-bold">{name}</h2>
        <p class="block text-sm text-gray-600">
            {short}
        </p>
    </a>);

}