import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ModelListing from "../components/modelListing"

const IndexPage = () => {

    const product = require('../../product.json');

    const _routes = product?.openapi?.paths || {};
    const routes = [];

    const definitions = product?.openapi?.components?.schemas || [];

    Object.keys(_routes).forEach(key => {

        const route = _routes[key];

        Object.keys(route).forEach(method => {

            routes.push({
                ...route.get,
                method,
                path: key
            });

        })

    })

    return <Layout>
        <section class="mt-16" id="features">
            <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div class="mx-auto h-full py-16 max-w-lg text-center lg:mx-0 lg:text-left flex align-top flex-col">
                    <h1 class="text-3xl font-bold sm:text-4xl">Models</h1>
                    <p class="mt-4 text-gray-600">
                    Currently, {product.name} implements {Object.keys(definitions).length} models. These models store data that is then used for requests and responses on {product.name}'s {routes.length} endpoints.
                    </p>
                </div>
                <div class="grid grid-cols-2 gap-4 sm:grid-cols-4 lg:grid-cols-6">
                    {
                        Object.keys(definitions).map((key) => {
                            
                            return ModelListing({ name: key, ...definitions[key] })
                        
                        })
                    }
                </div>
            </div>
        </section>
        <section class="h-50 w-full p-16"></section>
    </Layout>
}

export const Head = () => <Seo title="Products" />

export default IndexPage
